import React,{ useRef,useState } from 'react';
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Header from '../lib/Header';
import { useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form"
import axios from 'axios';

import MsgBox from '../lib/MsgBox';
import DrawerAlloc from '../drawer/DrawerAllocateVendor';
import DrawerQC from '../drawer/DrawerQC';
import DrawerQCRepair from '../drawer/DrawerQCRepair';
import DrawerQCApproval from '../drawer/DrawerQCApproval';
import DrawerQCPayment from '../drawer/DrawerQCPayment';
import DrawerAddProductPart from '../drawer/DrawerAddProductPart';

import PropTypes from 'prop-types';


const ProductPart = (props) => {
  const [cookies] = useCookies(['bas_']);
  const [contentLoading,setContentLoading] = useState(false)
  const [isMsgBox, setisMsgBox] = React.useState({})

  const allocRef = useRef();
  const qcRef = useRef();
  const repairRef = useRef();
  const approvalRef = useRef();
  const paymentRef = useRef();
  const qcListRef = useRef();
  const repairListRef = useRef();
  const approvalListRef = useRef();
  const paymentListRef = useRef();
  const deleteRef = useRef();
  const formRef = useRef();
  const addPartRef = useRef();

  const [page,setPage] = React.useState()

  const { id } = useParams();
  const _id = id.split('-');

  const [project,setProject] = useState([])
  const [item,setItem] = useState([])
  const [projectStatus, setProjectStatus] = useState()
  const [qc,setqc] = useState([])
  const [openTab, setOpenTab]=useState([])
  const [pagination,setPagination] = React.useState([])
  const [filterEnable, setFilterEnable] = useState(false)
  const [filter,setFilter] = React.useState({
    search: '',
    status: -1,
    perPage: 10,
    product_id: 0
  })
  const { register, setValue, handleSubmit,reset } = useForm()
  var tab = [];
  const onSubmit = (data) => {
      setPage(1)
      setFilter({
        search: data.search,
        perPage: data.perPage
        
      })
  }
  const submitProject =()=>{
    setContentLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Authorization",cookies.token);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch(props.config.hostApi + '/project/open/'+id, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if(result.success){
              setContentLoading(false)
              afterSubmitProject()
            }else{
              setContentLoading(false)
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Submit Project',
                msg: result.message,
              })
            }
          })
          .then((error)=>{
            setContentLoading(false)
          })
  }
  const closeProject =()=>{
    setContentLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Authorization",cookies.token);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch(props.config.hostApi + '/project/close/'+id, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if(result.success){
              setContentLoading(false)
              afterCloseProject()
            }else{
              setContentLoading(false)
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Close Project',
                msg: result.message,
              })
            }
          })
          .then((error)=>{
            setContentLoading(false)
          })
  }
  const clearProject =()=>{
    setContentLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Authorization",cookies.token);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch(props.config.hostApi + '/project/clear-photo/'+id, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if(result.success){
              setContentLoading(false)
              afterClearProject()
            }else{
              setContentLoading(false)
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Clear Project',
                msg: result.message,
              })
            }
          })
          .then((error)=>{
            setContentLoading(false)
          })
  }
  const deleteAllowcation = (product_id,id) => {
    setContentLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Authorization",cookies.token);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch(props.config.hostApi + '/vendor-allocation/'+id, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if(result.success){
              setContentLoading(false)
              reset()
              afterDeleteAllocation(product_id)
            }else{
              setContentLoading(false)
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Tukang Allocation',
                msg: result.message,
              })
            }
          })
          .then((error)=>{
            setContentLoading(false)
          })
      
      
}
const getQC = () => {
  setContentLoading(true)
  const header ={
    'Accept': 'application/json',
    'Authorization': cookies.token
  }
  axios.post( props.config.hostApi + 'qc',
   {
    page: 1,
    perPage: 1000,
    vendor_allocation_id: []
   },
   { headers: header })
    .then(res => {
        setqc(res.data.data)
        //console.log(res.data.data)
        setContentLoading(false)
    })
    .catch((error) => {
      setContentLoading(false)
    })
}

const getProject = () => {
  setContentLoading(true)
  const header ={
    'Accept': 'application/json',
    'Authorization': cookies.token
  }
  axios.post( props.config.hostApi + 
   'vendor-allocation',
   {
    project_id: [_id[0]],
    item_id:[_id[1]],
    search: filter.search,
    perPage: filter.perPage,
    page:page,
   },
   { headers: header })
    .then(res => {
        setProject(res.data.data)
        setItem(res.data.itemData)
        setProjectStatus(res.data.data[0].project_status)
        for(var i =0; i< res.data.data.length;i++){
          if(filter.product_id == res.data.data[i].id){
            tab[i] = true
          }else{
            tab[i] = false
          }
          
        }
        setOpenTab(tab)
        setPagination(res.data.pagination)
        setContentLoading(false)
    })
    .catch((error) => {
      setContentLoading(false)
    })
}
const HtmlTooltip = Mui.styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(openTab[props.index]);
  return (
    <React.Fragment>
      <Mui.TableRow>
        <Mui.TableCell component="th" scope="row">
              <Mui.Avatar
                  alt={row.description}
                  src={row.photo_url}
                  sx={{ width: 150, height: 150 }}
                  variant='square'
                />
        </Mui.TableCell>
        <Mui.TableCell>{row.description}</Mui.TableCell>
        <Mui.TableCell align="right">{row.qty}</Mui.TableCell>
        <Mui.TableCell align="right">{row.allocated}</Mui.TableCell>
        <Mui.TableCell align="right">{row.balance}</Mui.TableCell>

        <Mui.TableCell align='right'>
          <Mui.IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open)
            }}
          >
            {open === true ? <MuiIcon.KeyboardArrowUp /> : <MuiIcon.KeyboardArrowDown />}
          </Mui.IconButton>
        </Mui.TableCell>
        
      </Mui.TableRow>
      <Mui.TableRow>
        <Mui.TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Mui.Collapse in={open} timeout="auto" unmountOnExit>
            <Mui.Box sx={{ margin: 1 }}>
                <Mui.Grid container>
                    <Mui.Grid item xs={6} className="innerForm" align="left">
                        <Mui.Typography variant="h6">
                          Tukang Allocation
                        </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={6} className="innerForm" align="right">
                          {row.balance > 0 && (
                            <MuiJoy.Button
                                type="button"
                                variant="solid" 
                                onClick={ ()=> {
                                  if(cookies.job_id === 0 || cookies.job_id === 2 ){
                                    allocRef.current.openForm(row.id); 
                                  }else{
                                    setisMsgBox({
                                      open: true,
                                      status: 'error',
                                      title:'Access Denied',
                                      msg: 'Sorry, you don\'t have access to this menu',
                                    })
                                  }
                                }}
                                style={{ background:'#222222', margin:'0px 3px', padding: '0px 15px', borderRadius:'25px', fontSize: '12px'}} 
                                > <MuiIcon.BookmarkAdd /> &nbsp; Allocate Tukang
                            </MuiJoy.Button>
                        )}
                    </Mui.Grid>
                </Mui.Grid>


              <Mui.Table size="small" aria-label="purchases" sx={{marginTop:3, marginBottom:7}}>
                <Mui.TableHead>
                  <Mui.TableRow>
                    <Mui.TableCell>Tukang Name</Mui.TableCell>
                    <Mui.TableCell>Phone</Mui.TableCell>
                    <Mui.TableCell align="right">Qty</Mui.TableCell>
                    <Mui.TableCell align="center">OnProgress</Mui.TableCell>
                    <Mui.TableCell align="center">OnRepair</Mui.TableCell>
                    <Mui.TableCell align="center">Finished</Mui.TableCell>
                    <Mui.TableCell align="center">Approved</Mui.TableCell>
                    <Mui.TableCell align="center">Paid</Mui.TableCell>
                    {/*<Mui.TableCell align="center">QC / Repair / Approval / Payment</Mui.TableCell>*/}
                    <Mui.TableCell align="right">&nbsp;</Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableHead>
                 <Mui.TableBody>
                  {row.vendor_allocation.map((vendorAllocation,key) => (
                    <Mui.TableRow key={key}>
                      <Mui.TableCell component="th" scope="row">{vendorAllocation.vendor_name}</Mui.TableCell>
                      <Mui.TableCell>{vendorAllocation.vendor_phone}</Mui.TableCell>
                      <Mui.TableCell align="right">{vendorAllocation.qty}</Mui.TableCell>
                      <Mui.TableCell align="center" width={'10%'}>
                          <Mui.FormControl variant="outlined">
                            <Mui.OutlinedInput
                              size='small'
                              value={vendorAllocation.onprogress}
                              sx={{maxWidth:'120px'}}
                              endAdornment={
                                <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <Mui.Typography color="inherit">Quality Control Production</Mui.Typography>
                                        <em>Untuk melakukan review barang yang sudah selesai produksi</em>
                                      </React.Fragment>
                                    }
                                  >
                                    <Mui.InputAdornment position="end">
                                      <Mui.IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>{
                                          if(cookies.job_id === 0 || cookies.job_id === 3 ){
                                            qcRef.current.openForm(vendorAllocation.id,row.description,row.id);
                                          }else{
                                            setisMsgBox({
                                              open: true,
                                              status: 'error',
                                              title:'Access Denied',
                                              msg: 'Sorry, you don\'t have access to this menu',
                                            })
                                          }
                                        }}
                                        edge="end"
                                      >
                                        <MuiIcon.ContentPasteSearch />
                                      </Mui.IconButton>
                                    </Mui.InputAdornment>
                                  </HtmlTooltip>
                              }
                            />
                          </Mui.FormControl>

                      </Mui.TableCell>
                      <Mui.TableCell align="center" width={'10%'}>
                          <Mui.FormControl variant="outlined">
                            <Mui.OutlinedInput
                              size='small'
                              value={vendorAllocation.onrepair}
                              sx={{maxWidth:'120px'}}
                              endAdornment={
                                <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <Mui.Typography color="inherit">Product Repair</Mui.Typography>
                                        <em>Untuk melakukan review barang reject yang sudah selesai perbaikan</em>
                                      </React.Fragment>
                                    }
                                  >
                                    <Mui.InputAdornment position="end">
                                      <Mui.IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>{
                                          if(cookies.job_id === 0 || cookies.job_id === 3 ){
                                            repairRef.current.openForm(vendorAllocation.id,row.description,row.id);
                                          }else{
                                            setisMsgBox({
                                              open: true,
                                              status: 'error',
                                              title:'Access Denied',
                                              msg: 'Sorry, you don\'t have access to this menu',
                                            })
                                          }
                                        }}
                                        edge="end"
                                      >
                                        <MuiIcon.Handyman />
                                      </Mui.IconButton>
                                    </Mui.InputAdornment>
                                  </HtmlTooltip>
                              }
                            />
                          </Mui.FormControl>

                      </Mui.TableCell>
                      <Mui.TableCell align="center"  width={'10%'}>
                          <Mui.FormControl variant="outlined">
                            <Mui.OutlinedInput
                              size='small'
                              value={vendorAllocation.finished}
                              sx={{maxWidth:'120px'}}
                              endAdornment={
                                <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <Mui.Typography color="inherit">Approval</Mui.Typography>
                                        <em>Untuk melakukan review barang yang sudah lolos QC agar bisa dilakukan pembayaran</em>
                                      </React.Fragment>
                                    }
                                  >
                                    <Mui.InputAdornment position="end">
                                      <Mui.IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>{
                                          if(cookies.job_id === 0 || cookies.job_id === 2 ){
                                            approvalRef.current.openForm(vendorAllocation.id,row.description,row.id);
                                          }else{
                                            setisMsgBox({
                                              open: true,
                                              status: 'error',
                                              title:'Access Denied',
                                              msg: 'Sorry, you don\'t have access to this menu',
                                            })
                                          }
                                        }}
                                        edge="end"
                                      >
                                        <MuiIcon.ContentPasteGo />
                                      </Mui.IconButton>
                                    </Mui.InputAdornment>
                                  </HtmlTooltip>
                              }
                            />
                          </Mui.FormControl>
                      </Mui.TableCell>
                      <Mui.TableCell align="center" width={'10%'}>
                        <Mui.FormControl variant="outlined">
                            <Mui.OutlinedInput
                              size='small'
                              value={vendorAllocation.approved}
                              sx={{maxWidth:'90px'}}
                            />
                          </Mui.FormControl>
                      </Mui.TableCell>
                      <Mui.TableCell align="center" width={'10%'}>
                        <Mui.FormControl variant="outlined">
                            <Mui.OutlinedInput
                              size='small'
                              value={vendorAllocation.paid}
                              sx={{maxWidth:'120px'}}
                              endAdornment={
                                <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <Mui.Typography color="inherit">Payment</Mui.Typography>
                                        <em>Untuk melakukan pecatatan data pembayaran jasa tukang</em>
                                      </React.Fragment>
                                    }
                                  >
                                    <Mui.InputAdornment position="end">
                                      <Mui.IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>{
                                          if(cookies.job_id === 0 || cookies.job_id === 4 ){
                                            paymentRef.current.openForm(vendorAllocation.id,row.description,row.id);
                                          }else{
                                            setisMsgBox({
                                              open: true,
                                              status: 'error',
                                              title:'Access Denied',
                                              msg: 'Sorry, you don\'t have access to this menu',
                                            })
                                          }
                                        }}
                                        edge="end"
                                      >
                                        <MuiIcon.PointOfSale />
                                      </Mui.IconButton>
                                    </Mui.InputAdornment>
                                  </HtmlTooltip>
                              }
                            />
                          </Mui.FormControl>
                      </Mui.TableCell>
                      {/*<Mui.TableCell align="center">
                        {projectStatus == 1 ? (
                          <>
                          <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Mui.Typography color="inherit">Quality Control Production</Mui.Typography>
                                  <em>Untuk melakukan review barang yang sudah selesai produksi</em>
                                </React.Fragment>
                              }
                            >
                              <MuiJoy.Button
                                  type="button"
                                  variant="solid" 
                                  style={{ background:'none',color :'#222222', margin:'0px 3px', padding: '0px 5px', borderRadius:'25px', fontSize: '12px'}} 
                                  onClick={ ()=> {
                                    qcRef.current.openForm(vendorAllocation.id,row.description,row.id); 
                                  }}
                                  > <MuiIcon.ContentPasteSearch />
                                </MuiJoy.Button>
                          </HtmlTooltip> &nbsp;  &nbsp; 
                          <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Mui.Typography color="inherit">Quality Control Product Repair</Mui.Typography>
                                  <em>Untuk melakukan review barang reject yang sudah selesai perbaikan</em>
                                </React.Fragment>
                              }
                            >
                              <MuiJoy.Button
                                  type="button"
                                  variant="solid" 
                                  style={{ background:'none',color :'#222222', margin:'0px 3px', padding: '0px 5px', borderRadius:'25px', fontSize: '12px'}} 
                                  onClick={ ()=> {
                                    repairRef.current.openForm(vendorAllocation.id,row.description,row.id); 
                                  }}
                                  > <MuiIcon.Handyman />
                                </MuiJoy.Button>
                          </HtmlTooltip> &nbsp;  &nbsp; 
                          <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Mui.Typography color="inherit">Approval</Mui.Typography>
                                  <em>Untuk melakukan review barang yang sudah lolos QC agar bisa dilakukan pembayaran</em>
                                </React.Fragment>
                              }
                            >
                              <MuiJoy.Button
                                  type="button"
                                  variant="solid" 
                                  style={{ background:'none',color :'#222222', margin:'0px 3px', padding: '0px 5px', borderRadius:'25px', fontSize: '12px'}} 
                                  onClick={ ()=> {
                                    approvalRef.current.openForm(vendorAllocation.id,row.description,row.id); 
                                  }}
                                  > <MuiIcon.ContentPasteGo />
                                </MuiJoy.Button>
                          </HtmlTooltip> &nbsp;  &nbsp; 
                          <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Mui.Typography color="inherit">Payment</Mui.Typography>
                                  <em>Untuk melakukan pecatatan data pembayaran jasa tukang</em>
                                </React.Fragment>
                              }
                            >
                              <MuiJoy.Button
                                  type="button"
                                  variant="solid" 
                                  style={{ background:'none',color :'#222222', margin:'0px 3px', padding: '0px 5px', borderRadius:'25px', fontSize: '12px'}} 
                                  onClick={ ()=> {
                                    paymentRef.current.openForm(vendorAllocation.id,row.description,row.id); 
                                  }}
                                  > <MuiIcon.PointOfSale />
                                </MuiJoy.Button>
                          </HtmlTooltip>
                          </>
                        ):''}
                      </Mui.TableCell>*/}
                      <Mui.TableCell align="right">
                        {projectStatus == 0 ? (
                            <MuiJoy.Button
                                  type="button"
                                  variant="solid" 
                                  style={{ background:'none',color :'#FF0000', margin:'0px 3px', padding: '0px 5px', borderRadius:'25px', fontSize: '12px'}} 
                                  onClick={ ()=> {
                                    deleteAllowcation(row.id,vendorAllocation.id); 
                                  }}
                                  > <MuiIcon.HighlightOff />
                                </MuiJoy.Button>
                          ):''}
                      </Mui.TableCell>
                    </Mui.TableRow>
                  ))}
                </Mui.TableBody>
              </Mui.Table>
            </Mui.Box>
          </Mui.Collapse>
        </Mui.TableCell>
      </Mui.TableRow>
    </React.Fragment>
  );
}

const afterSubmitProject=()=>{
  setisMsgBox({
    open: true,
    status: 'success',
    title:'Submit Project',
    msg: 'Project submiting successfully.'
  })
  getProject()
  getQC()
}
const afterCloseProject=()=>{
  setisMsgBox({
    open: true,
    status: 'success',
    title:'Close Project',
    msg: 'Project closing successfully.'
  })
  getProject()
  getQC()
}
const afterClearProject=()=>{
  setisMsgBox({
    open: true,
    status: 'success',
    title:'Clear Project',
    msg: 'Project Photos Clearing successfully.'
  })
  getProject()
  getQC()
}
const afterDeleteAllocation=(product_id)=>{
  setFilter({
    search: filter.search,
    perPage: filter.perPage,
    status: filter.status,
    product_id: product_id
    
  })
  setisMsgBox({
    open: true,
    status: 'success',
    title:'Tukang Allocation',
    msg: 'Delete allocation successfully.'
  })
  getProject()
  getQC()
}
const afterAllocation=(product_id)=>{
  setFilter({
    search: filter.search,
    perPage: filter.perPage,
    status: filter.status,
    product_id: product_id
    
  })
  setisMsgBox({
    open: true,
    status: 'success',
    title:'Tukang Allocation',
    msg: 'New Tukang allocation successfully.'
  })
  getProject()
  getQC()
}
const afterSave=()=>{
  setisMsgBox({
    open: true,
    status: 'success',
    title:'Add New Product Part',
    msg: 'Saving new Product Part successfully.'
  })

  getProject()
  getQC()
}


const refreshQC=(form,id,name,product_id)=>{
  setFilter({
    search: filter.search,
    perPage: filter.perPage,
    status: filter.status,
    product_id: product_id
    
  })
  switch (form){
    case 0:
      getProject()
      getQC()
      //qcRef.current.openForm(id,name); 
    break;
    case 1:
      getProject()
      getQC()
    break;
  }
  
}

React.useEffect(() => {
  getProject()
  getQC()
}, [filter,page]); 

  return (
    <div>
      <MsgBox config={isMsgBox} />
      <DrawerAlloc ref={allocRef} config={props.config} refreshVendorAllocation={afterAllocation} />
      <DrawerQC ref={qcRef} config={props.config} refreshQC={refreshQC} qc={qc} />
      <DrawerQCRepair ref={repairRef} config={props.config} refreshQC={refreshQC} qc={qc} />
      <DrawerQCApproval ref={approvalRef} config={props.config} refreshQC={refreshQC} qc={qc} />
      <DrawerQCPayment ref={paymentRef} config={props.config} refreshQC={refreshQC} qc={qc} />
      <DrawerAddProductPart ref={addPartRef} config={props.config} refreshProductSave={afterSave} qc={qc} />
      <Header config={props.config} />
      <Mui.Grid className='topBar' container style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}>
        <Mui.Grid item md={12}>
          <Mui.Toolbar disableGutters>
              <Mui.Breadcrumbs aria-label="breadcrumb">
                  <Mui.Link
                    underline="none"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/"
                  >
                    <MuiIcon.Home sx={{ mr: 0.5 }} fontSize="inherit" />
                    Home
                  </Mui.Link>
                  
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                  >
                    Project / {_id[0]} / {/*_id[1]*/} {item.description}
                  </Mui.Typography>
              </Mui.Breadcrumbs>

              <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
              {_id[1] > 0 ? (
                <MuiJoy.Button
                    type="button"
                    variant="solid" 
                    style={{ background:'#222222', margin:'0px 3px', padding: '0px 15px', borderRadius:'25px', fontSize: '12px'}} 
                    onClick={ ()=> {
                      addPartRef.current.openForm( _id[0], _id[1]); 
                    }}
                    > <MuiIcon.BookmarkAdd /> &nbsp; Add New Part
                </MuiJoy.Button>
              ):('')}
              {/*projectStatus == 0 ? (
              <MuiJoy.Button
                  type="button"
                  variant="solid" 
                  style={{ background:'#222222', margin:'0px 3px', padding: '0px 15px', borderRadius:'25px', fontSize: '12px'}} 
                  onClick={ ()=> {
                    submitProject()
                  }}
                  > <MuiIcon.BookmarkAdd /> &nbsp; Submit Project
              </MuiJoy.Button>
              ):''}
              {projectStatus == 1 ? (
              <MuiJoy.Button
                  type="button"
                  variant="solid" 
                  style={{ background:'#222222', margin:'0px 3px', padding: '0px 15px', borderRadius:'25px', fontSize: '12px'}} 
                  onClick={ ()=> {
                    closeProject()
                  }}
                  > <MuiIcon.BookmarkAdded /> &nbsp; Close Project
              </MuiJoy.Button>
              ):''}
              {projectStatus == 2 ? (
              <MuiJoy.Button
                  type="button"
                  variant="solid" 
                  style={{ background:'#F91101', margin:'0px 3px', padding: '0px 15px', borderRadius:'25px', fontSize: '12px'}} 
                  onClick={ ()=> {
                    clearProject()
                  }}
                  > <MuiIcon.DisabledByDefault /> &nbsp; Clear Project Photos
              </MuiJoy.Button>
              ):''*/}
              <MuiJoy.Button
                  type="button"
                  variant="solid" 
                  style={{ background:'#555555', margin:'0px 3px', padding: '0px 15px', borderRadius:'25px', fontSize: '12px'}} 
                  onClick={ ()=> {
                    if(filterEnable)
                      setFilterEnable(false)
                    else  
                      setFilterEnable(true)
                  }}
                  >  <MuiIcon.ManageSearch /> &nbsp; Filter
                   
                </MuiJoy.Button>
                
          </Mui.Toolbar>
        </Mui.Grid>
      </Mui.Grid>

      {(filterEnable) ? (
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Mui.Grid container 
              style={{
              backgroundColor:'#f8f8f8',
              padding:'10px 20px 10px 20px'
            }}>
              <Mui.Box sx={{ flexGrow: 1, margin: '5px' }}>
                  <Mui.TextField label="Keyword" size="small" variant="outlined" className="inputField"  {...register("search")} />
              </Mui.Box>
              <Mui.Box sx={{minWidth:'100px', margin:'5px'}}>
                  <Mui.FormControl size="small" className="inputField">
                      <Mui.TextField
                          select
                          size="small"
                          label="PerPage"
                          {...register("perPage")}
                      >
                          <Mui.MenuItem key={0} value={10}>10 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={1} value={25}>25 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={2} value={50}>50 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={3} value={100}>100 Orders</Mui.MenuItem>
                      </Mui.TextField>
                  </Mui.FormControl>
              </Mui.Box>
              <Mui.Box sx={{margin: '7px 5px'}}>
                    <MuiJoy.Button
                      type="submit"
                      variant="solid" 
                      loading={contentLoading} 
                      sx={{ margin: '0px 3px'}}
                      startDecorator={<MuiIcon.NavigateNext />}
                      loadingPosition="start"
                      > Show
                    </MuiJoy.Button>
              </Mui.Box>
          </Mui.Grid>
        </form>
        ):('')}

      <Mui.Grid container spacing={2}>
      {(contentLoading) ? (
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <h2>Please wait... </h2>
                    <Mui.Box sx={{ width: '100%' }}>
                      <Mui.LinearProgress />
                    </Mui.Box>
                </Mui.Grid>
          ) : (
              <>
              <Mui.Grid item xs={12}>
                  <Mui.Box style={{padding:'20px'}}>
                      <Mui.Table aria-label="collapsible table">
                            <Mui.TableHead>
                              <Mui.TableRow>
                                <Mui.TableCell>Product Item</Mui.TableCell>
                                <Mui.TableCell>&nbsp;</Mui.TableCell>
                                <Mui.TableCell align="right">Qty</Mui.TableCell>
                                <Mui.TableCell align="right">Allocated</Mui.TableCell>
                                <Mui.TableCell align="right">Balance</Mui.TableCell>
                                <Mui.TableCell align="right">&nbsp;</Mui.TableCell>
                              </Mui.TableRow>
                            </Mui.TableHead>
                            <Mui.TableBody>
                              { project.map((row,key) => (
                                  <Row key={key} row={row} index={key} />
                              ))}
                            </Mui.TableBody>
                          </Mui.Table>
                  </Mui.Box>
                </Mui.Grid>
              </>
          )}
      </Mui.Grid>
    </div>
  );
}

export default ProductPart;



