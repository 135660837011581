import React,{ useRef,useState } from 'react';
import { useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import { useForm, SubmitHandler } from "react-hook-form"

import DrawerAddVendor from '../drawer/DrawerAddVendor';
import DrawerEditVendor from '../drawer/DrawerEditVendor';
import DrawerDeleteVendor from '../drawer/DrawerDeleteVendor';


import Header from '../lib/Header';
import axios from 'axios';
import MsgBox from '../lib/MsgBox';
import GetStatus from '../lib/GetStatus';
import Cstr from '../lib/ConvertString';



const Vendors = (props) => {

  const [cookies] = useCookies(['bas_']);
  const [contentLoading,setContentLoading] = useState(false)
  const [isMsgBox, setisMsgBox] = React.useState({})
  let { customerId } = useParams();
  const [vendor,setVendor] = React.useState([])
  const [loading,setLoading] = React.useState(false)

  const addRef = useRef();
  const editRef = useRef();
  const deleteRef = useRef();
  const formRef = useRef();
  const [taxo,setTaxo] = useState([]);
  const [page,setPage] = React.useState()
  const { register, setValue, handleSubmit,reset } = useForm()
  var obj;
  const onSubmit = (data) => {
      setPage(1)
      setFilter({
        search: data.search,
        perPage: data.perPage
        
      })
  }
  const changePage = (event,value) => {
    setPage(value);
  };
  const [pagination,setPagination] = React.useState([])
  const [filterEnable, setFilterEnable] = useState(false)
  const [filter,setFilter] = React.useState({
    search: '',
    perPage: 5

  })

  const afterSave=()=>{
    getVendor();
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Add New Tukang',
      msg: 'Add new Tukang successfully.'
    })
  }

  const afterDelete=()=>{
    getVendor();
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Delete Tukang',
      msg: 'Delete Tukang successfully'
    })
  }
  const afterUpdate=()=>{
    getVendor();
    setisMsgBox({
      open: true,
      status: 'success',
      title:'Update Tukang',
      msg: 'Update Tukang successfully'
    })
  }
  const getVendor = () => {
    setContentLoading(true)
    const header ={
      'Accept': 'application/json',
      'Authorization': cookies.token
    }
    axios.post( props.config.hostApi + 
     'vendor',
     {
      search: filter.search,
      perPage: filter.perPage,
      page:page,
     },
     { headers: header })
      .then(res => {
          setVendor(res.data.data)
          setPagination(res.data.pagination)
          setContentLoading(false)
      })
      .catch((error) => {
        setContentLoading(false)
      })
  }
  React.useEffect(() => {
    getVendor();
  }, [filter,page]); 


  return (
    <div>
      <MsgBox config={isMsgBox} />
      
      <DrawerAddVendor ref={addRef} config={props.config} refreshVendorSave={afterSave} />
      <DrawerEditVendor ref={editRef} config={props.config} refreshVendorUpdate={afterUpdate} />
      <DrawerDeleteVendor ref={deleteRef} config={props.config} refreshVendorDelete={afterDelete} />
      <Header config={props.config} />
      <Mui.Toolbar className='topBar' disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}>
            <Mui.Breadcrumbs aria-label="breadcrumb">
                <Mui.Link
                  underline="none"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="inherit"
                  href="/"
                >
                  <MuiIcon.Home sx={{ mr: 0.5 }} fontSize="inherit" />
                  Home
                </Mui.Link>
                <Mui.Typography
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="text.primary"
                >
                  Tukang ({pagination.totalRecords})
                </Mui.Typography>
            </Mui.Breadcrumbs>
            <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
            <MuiJoy.Button
                  type="button"
                  variant="solid" 
                  style={{ background:'#222222', margin:'0px 3px', padding: '0px 15px', borderRadius:'25px', fontSize: '12px'}} 
                  onClick={ ()=> {
                    addRef.current.openForm();
                  }} 
                  > <MuiIcon.BookmarkAdd /> &nbsp; Add New Tukang
              </MuiJoy.Button>
              <MuiJoy.Button
                  type="button"
                  variant="solid" 
                  style={{ background:'#555555', margin:'0px 3px', padding: '0px 15px', borderRadius:'25px', fontSize: '12px'}} 
                  onClick={ ()=> {
                    if(filterEnable)
                      setFilterEnable(false)
                    else  
                      setFilterEnable(true)
                  }}
                  >  <MuiIcon.ManageSearch /> &nbsp; Filter
                   
                </MuiJoy.Button>
            
      </Mui.Toolbar>
      {(filterEnable) ? (
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Mui.Grid container 
              style={{
              backgroundColor:'#f8f8f8',
              padding:'10px 20px 10px 20px'
            }}>
              <Mui.Box sx={{ flexGrow: 1, margin: '5px' }}>
                  <Mui.TextField label="Keyword" size="small" variant="outlined" className="inputField"  {...register("search")} />
              </Mui.Box>
              <Mui.Box sx={{minWidth:'100px', margin:'5px'}}>
                  <Mui.FormControl size="small" className="inputField">
                      <Mui.TextField
                          select
                          size="small"
                          label="PerPage"
                          {...register("perPage")}
                      >
                          <Mui.MenuItem key={0} value={10}>10 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={1} value={25}>25 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={2} value={50}>50 Orders</Mui.MenuItem>
                          <Mui.MenuItem key={3} value={100}>100 Orders</Mui.MenuItem>
                      </Mui.TextField>
                  </Mui.FormControl>
              </Mui.Box>
              <Mui.Box sx={{margin: '7px 5px'}}>
                    <MuiJoy.Button
                      type="submit"
                      variant="solid" 
                      loading={loading} 
                      sx={{ margin: '0px 3px'}}
                      startDecorator={<MuiIcon.NavigateNext />}
                      loadingPosition="start"
                      > Show
                    </MuiJoy.Button>
              </Mui.Box>
          </Mui.Grid>
        </form>
        ):('')}

      <Mui.Grid container spacing={2}>
        {(contentLoading) ? (
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <h2>Please wait... </h2>
                    <Mui.Box sx={{ width: '100%' }}>
                      <Mui.LinearProgress />
                    </Mui.Box>
                </Mui.Grid>
            ) : (
              <>
                <Mui.Grid item xs={12} style={{margin:'20px'}}>
                    <Mui.Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <Mui.TableHead>
                            <Mui.TableRow>
                                <Mui.TableCell>ID</Mui.TableCell>
                                <Mui.TableCell>Name</Mui.TableCell>
                                <Mui.TableCell>Alias</Mui.TableCell>
                                <Mui.TableCell>Address</Mui.TableCell>
                                <Mui.TableCell>Phone</Mui.TableCell>
                                <Mui.TableCell>&nbsp;</Mui.TableCell>
                            </Mui.TableRow>
                        </Mui.TableHead>
                        <Mui.TableBody>
                        { vendor.map((_vendor,key) => (
                          
                            <Mui.TableRow
                            key={key}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <Mui.TableCell component="th" scope="row">
                                    {_vendor.id}
                                </Mui.TableCell>
                                <Mui.TableCell>{_vendor.name}</Mui.TableCell>
                                <Mui.TableCell>{_vendor.alias}</Mui.TableCell>
                                <Mui.TableCell>{_vendor.address}</Mui.TableCell>
                                <Mui.TableCell>{_vendor.phone}</Mui.TableCell>
                                <Mui.TableCell align="right">
                                  <MuiJoy.Dropdown>
                                    <MuiJoy.MenuButton
                                      slots={{ root: MuiJoy.IconButton }}
                                      slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
                                    >
                                      <MuiIcon.MoreVert />
                                    </MuiJoy.MenuButton>
                                    <MuiJoy.Menu placement="bottom-end">

                                    
                                      <MuiJoy.MenuItem onClick={ ()=> {
                                            editRef.current.openForm(_vendor);
                                        }}>
                                        <MuiJoy.ListItemDecorator>
                                          <MuiIcon.Edit />
                                        </MuiJoy.ListItemDecorator>{' '}
                                        Edit
                                      </MuiJoy.MenuItem>
                                      
                                      <MuiJoy.MenuItem variant="soft" color="danger"  onClick={ ()=> {
                                            deleteRef.current.openForm(_vendor.id); 
                                          }}>
                                        <MuiJoy.ListItemDecorator sx={{ color: 'inherit' }}>
                                          <MuiIcon.NotInterested />
                                        </MuiJoy.ListItemDecorator>{' '}
                                        Delete
                                      </MuiJoy.MenuItem>
                                      
                                    </MuiJoy.Menu>
                                  </MuiJoy.Dropdown>
                                </Mui.TableCell>
                            </Mui.TableRow>
                                      ))}
                        </Mui.TableBody>
                    </Mui.Table>
                </Mui.Grid>
                
                <Mui.Grid item xs={12} >
                    <Mui.Pagination 
                        sx={{ 
                          '& > .MuiPagination-ul': {
                            justifyContent: 'center',
                          },
                          p:2,
                        }} count={pagination.totalPages} page={page} onChange={changePage} color="primary" />
                </Mui.Grid>
              </>
            )}
      </Mui.Grid>
    </div>
  );
}

export default Vendors;
