import React,  { useRef,forwardRef, useImperativeHandle, useState } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiJoy from '@mui/joy';
import MsgBox from "../lib/MsgBox";
import { useForm, SubmitHandler } from "react-hook-form"

const DrawerEditVendor = forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const formRef = useRef();
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState([])
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const { register, setValue, handleSubmit,reset } = useForm()
    const onSubmit = (data) => {
      loadingOn()
        
      const myHeaders = new Headers();
      myHeaders.append("Authorization",cookies.token);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams(new FormData(formRef.current));
      urlencoded.append("id", state.vendor.id);

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };


      fetch(props.config.hostApi + 'vendor/update', requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log(result)
            if(result.success){
              refreshParent()
              loadingOff()
              reset()
            }else{
              setError(result.errors)
              loadingOff()
              setisMsgBox({
                open: true,
                status: 'error',
                title:'Save Tukang',
                msg: result.message,
              })
            }
          })
          .then((error)=>{
            console.log(error)
            loadingOff()
          })
          
    }
    const [state, setState] = React.useState({
      isForm:false,
      vendor:[]
    });

    useImperativeHandle(ref, () => ({
      openForm: (vendor) => {
        setState({ 
          isForm: true, 
          vendor: vendor
        })
        setValue("name", vendor.name)
        setValue("alias", vendor.alias)
        setValue("address", vendor.address)
        setValue("phone", vendor.phone)
        setValue("email", vendor.email)
      }
   }));

   const loadingOn = () =>{
    setLoading(true)
  }
  const loadingOff = () =>{
    setLoading(false)
  }
    const refreshParent = (event) => {
      props.refreshVendorUpdate()
      setState({
        isForm:false,
        vendor:[]
      })
    }


  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm} /*onClose={ ()=>{setState({isForm:false,id:'',device:[],taxo:[]})}}*/ PaperProps={{sx: { width: "90%", maxWidth: "800px" },}}>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Mui.Toolbar disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}> 
              <Mui.Typography
                sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                color="text.secondary"
              >
                Edit Tukang with ID : {state.vendor.id}
              </Mui.Typography>
              <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
              

              <MuiJoy.Button
                variant="solid"
                onClick={()=> setState({
                  isForm:false,
                  vendor:[]
                })}  
                startDecorator={<MuiIcon.Replay/>}
              > Back 
              </MuiJoy.Button>
              <MuiJoy.Button
                type="submit"
                variant="solid" 
                loading={loading} 
                sx={{ margin: '0px 3px'}}
                startDecorator={<MuiIcon.Save />}
                loadingPosition="start"
                > Update
              </MuiJoy.Button>
        </Mui.Toolbar>
        <Mui.DialogContent>
                <Mui.Grid container>
                    <Mui.Grid item xs={12} className="innerForm" textAlign={"right"}>
                        <span>* Require Field</span>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} className="innerForm">
                        <Mui.TextField 
                            label="*Name" 
                            size="small" 
                            variant="outlined" 
                            className="inputField" 
                            {...register("name")} />
                            
                            {error.filter(error => error.path === 'name').length > 0 && <p className="error">{error.filter(error => error.path === 'name')[0].msg}</p>}
                
                    </Mui.Grid>
                    <Mui.Grid item xs={12} className="innerForm">
                        <Mui.TextField 
                          label="Alias" 
                          size="small" 
                          variant="outlined" 
                          className="inputField" 
                          {...register("alias")} />

                          {error.filter(error => error.path === 'alias').length > 0 && <p className="error">{error.filter(error => error.path === 'alias')[0].msg}</p>}
                
                    </Mui.Grid>
                    <Mui.Grid item xs={12} className="innerForm">
                        <Mui.TextField label="Address " size="small" variant="outlined" className="inputField" {...register("address")} />
                        {error.filter(error => error.path === 'address').length > 0 && <p className="error">{error.filter(error => error.path === 'address')[0].msg}</p>}
                
                    </Mui.Grid>
                    <Mui.Grid item xs={12} className="innerForm">
                        <Mui.TextField label="*Phone " size="small" variant="outlined" className="inputField" {...register("phone")} />
                        {error.filter(error => error.path === 'phone').length > 0 && <p className="error">{error.filter(error => error.path === 'phone')[0].msg}</p>}
                
                    </Mui.Grid>
                    <Mui.Grid item xs={12} className="innerForm">
                        <Mui.TextField label="*Email " size="small" variant="outlined" className="inputField" {...register("email")} />
                        {error.filter(error => error.path === 'email').length > 0 && <p className="error">{error.filter(error => error.path === 'email')[0].msg}</p>}
                
                    </Mui.Grid>
                      
                </Mui.Grid>
        </Mui.DialogContent>
        
        </form>
      </Mui.Drawer>  
    </>
  );
});

export default DrawerEditVendor;
